<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-form modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>สินค้าร่วมรายการ</span>
                        </h4>

                        <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="info mb-3">
                            <span><strong>หัวข้อ</strong> {{ title }}</span>
                            <span><strong>เงื่อนไข</strong> {{ description }}</span>
                        </div>

                        <div class="search">
                            <div class="form">
                                <div class="row">
                                    <div class="col col-6 col-md-3">
                                        <div class="input-field">
                                            <label>สถานะสินค้า</label>
                                            <dropdown
                                                v-model="form.status"
                                                placeholder="สถานะ"
                                                :classes="{ input: [ 'square', 'input-option' ] }"
                                                :options="option.status">
                                            </dropdown>
                                        </div>
                                    </div>
                                    <div class="col col-6 col-md-3">
                                        <div class="input-field">
                                            <label>ยี่ห้อยาง</label>
                                            <dropdown
                                                v-model="form.brand"
                                                placeholder="ยี่ห้อยาง"
                                                :classes="{ input: [ 'square', 'input-option' ] }"
                                                :options="option.brand"
                                                @change="changeBrand($event)">
                                            </dropdown>
                                        </div>
                                    </div>
                                    <div class="col col-6 col-md-3">
                                        <div class="input-field">
                                            <label>รุ่นยาง</label>
                                            <dropdown
                                                v-model="form.model"
                                                placeholder="รุ่น"
                                                :classes="{ input: [ 'square', 'input-option' ] }"
                                                :options="option.model">
                                            </dropdown>
                                        </div>
                                    </div>
                                    <div class="col col-6 col-md-3">
                                        <div class="input-field">
                                            <label>SKU</label>
                                            <input type="text"
                                                placeholder="SKU"
                                                v-model="form.sku"
                                                class="square"
                                            />
                                        </div>
                                    </div>
                                    <div class="col col-6 col-md-3">
                                        <div class="input-field">
                                            <label>ความกว้างยาง</label>
                                            <dropdown
                                                v-model="form.width"
                                                placeholder="ความกว้างยาง"
                                                :classes="{ input: [ 'square', 'input-option' ] }"
                                                :options="option.width"
                                                @change="changeTireSizeOption()">
                                            </dropdown>
                                        </div>
                                    </div>
                                    <div class="col col-6 col-md-3">
                                        <div class="input-field">
                                            <label>ซีรี่ย์</label>
                                            <dropdown
                                                v-model="form.ratio"
                                                placeholder="ซีรี่ย์"
                                                :classes="{ input: [ 'square', 'input-option' ] }"
                                                :options="option.ratio"
                                                @change="changeTireSizeOption()">
                                            </dropdown>
                                        </div>
                                    </div>
                                    <div class="col col-6 col-md-3">
                                        <div class="input-field">
                                            <label>ขอบล้อ</label>
                                            <dropdown
                                                v-model="form.diameter"
                                                placeholder="ขอบล้อ"
                                                :classes="{ input: [ 'square', 'input-option' ] }"
                                                :options="option.diameter"
                                                @change="changeTireSizeOption()">
                                            </dropdown>
                                        </div>
                                    </div>
                                    <div class="col col-6 col-md-3">
                                        <div class="input-field">
                                            <label>ปีผลิต</label>
                                            <dropdown
                                                v-model="form.year"
                                                placeholder="ปีผลิต"
                                                :classes="{ input: [ 'square', 'input-option' ] }"
                                                :options="option.year">
                                            </dropdown>
                                        </div>
                                    </div>
                                    <div class="col col-6 col-md-3">
                                        <div class="input-field">
                                            <label>กรองสินค้าร่วมรายการ</label>
                                            <dropdown
                                                v-model="form.integrateType"
                                                placeholder="กรองสินค้าร่วมรายการ"
                                                :classes="{ input: [ 'square', 'input-option' ] }"
                                                :options="option.integrate">
                                            </dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-2 text-center">
                                    <button class="btn btn-main btn-search" @click="submitSearch()"><font-awesome-icon :icon="['fas', 'search']" class="icon" /> ค้นหา</button>
                                    <button class="btn btn-reset" @click="resetForm()">รีเซ็ต</button>
                                </div>
                            </div>
                        </div>

                        <label class="result-text mt-3">ผลการค้นหา <strong>{{ totalResult }}</strong> รายการ</label>
                        <div class="action">
                            <div>
                                <button class="btn btn-sm btn-add-selected square" @click="toggleSelctedProduct(true)">ร่วมรายการสินค้าที่เลือก ({{ resultList.filter(x => x.isSelect).length }})</button>
                                <button class="btn btn-sm btn-remove-selected square" @click="toggleSelctedProduct(false)">ถอนสินค้าที่เลือก ({{ resultList.filter(x => x.isSelect).length }})</button>
                            </div>
                            <div>
                                <button class="btn btn-sm btn-add-all square" @click="toggleAllProduct(true)">ร่วมรายการสินค้าทั้งหมด</button>
                                <button class="btn btn-sm btn-remove-all square" @click="toggleAllProduct(false)">ถอนสินค้าทั้งหมดออก</button>
                            </div>
                        </div>

                        <div class="vue-dragscoll mt-3" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
                            <table>
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            <button class="btn btn-sm btn-outline-secondary"
                                                v-if="!isSelectAll"
                                                 @click="toggleAllSelection(true)">
                                            </button>
                                            <button class="btn btn-sm btn-primary"
                                                v-if="isSelectAll"
                                                 @click="toggleAllSelection(false)">
                                                <b-icon icon="check"></b-icon>
                                            </button>
                                        </th>
                                        <th class="sortable"
                                            :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.INTEGRATE }"
                                            @click="sortStock(constants.SORT_STOCK_COLUMN.INTEGRATE)">
                                            ร่วมรายการ
                                            <b-icon icon="chevron-down" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.INTEGRATE">
                                            </b-icon>
                                            <b-icon icon="chevron-up" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.INTEGRATE">
                                            </b-icon>
                                        </th>
                                        <th class="sortable"
                                            :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.INTEGRATE }"
                                            @click="sortStock(constants.SORT_STOCK_COLUMN.ID)">
                                            รหัส
                                            <b-icon icon="chevron-down" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.ID">
                                            </b-icon>
                                            <b-icon icon="chevron-up" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.ID">
                                            </b-icon>
                                        </th>
                                        <th class="sortable"
                                            :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.BRAND }"
                                            @click="sortStock(constants.SORT_STOCK_COLUMN.BRAND)">
                                            ยี่ห้อ/รุ่น
                                            <b-icon icon="chevron-down" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.BRAND">
                                            </b-icon>
                                            <b-icon icon="chevron-up" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.BRAND">
                                            </b-icon>
                                        </th>
                                        <th class="sortable"
                                            :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.SIZE }"
                                            @click="sortStock(constants.SORT_STOCK_COLUMN.SIZE)">
                                            ขนาด
                                            <b-icon icon="chevron-down" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.SIZE">
                                            </b-icon>
                                            <b-icon icon="chevron-up" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.SIZE">
                                            </b-icon>
                                        </th>
                                        <th class="sortable text-center"
                                            :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.YEAR }"
                                            @click="sortStock(constants.SORT_STOCK_COLUMN.YEAR)">
                                            ปีผลิต
                                            <b-icon icon="chevron-down" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.YEAR">
                                            </b-icon>
                                            <b-icon icon="chevron-up" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.YEAR">
                                            </b-icon>
                                        </th>
                                        <th class="sortable text-end"
                                            :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.QUANTITY }"
                                            @click="sortStock(constants.SORT_STOCK_COLUMN.QUANTITY)">
                                            จำนวน
                                            <b-icon icon="chevron-down" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.QUANTITY">
                                            </b-icon>
                                            <b-icon icon="chevron-up" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.QUANTITY">
                                            </b-icon>
                                        </th>
                                        <th class="sortable text-end"
                                            :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE }"
                                            @click="sortStock(constants.SORT_STOCK_COLUMN.PRICE)">
                                            ราคา (ติดตั้ง)
                                            <b-icon icon="chevron-down" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE">
                                            </b-icon>
                                            <b-icon icon="chevron-up" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE">
                                            </b-icon>
                                        </th>
                                        <th class="sortable text-end"
                                            :class="{ 'active': currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE_DELIVERY }"
                                            @click="sortStock(constants.SORT_STOCK_COLUMN.PRICE_DELIVERY)">
                                            ราคา (จัดส่ง)
                                            <b-icon icon="chevron-down" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.ASC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE_DELIVERY">
                                            </b-icon>
                                            <b-icon icon="chevron-up" 
                                                class="icon"
                                                v-if="currentSortDirection === constants.SORT_STOCK_DIRECTION.DESC 
                                                    && currentSortColumn === constants.SORT_STOCK_COLUMN.PRICE_DELIVERY">
                                            </b-icon>
                                        </th>
                                        <th class="text-center">สถานะ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in resultList" :key="index">
                                        <td class="text-center">
                                            <button class="btn btn-sm btn-outline-secondary"
                                                v-if="!item.isSelect"
                                                @click="toggleSelection(item, true)">
                                            </button>
                                            <button class="btn btn-sm btn-primary"
                                                v-if="item.isSelect"
                                                @click="toggleSelection(item, false)">
                                                <b-icon icon="check"></b-icon>
                                            </button>
                                        </td>
                                        <td class="text-center">
                                            <b-icon icon="check" v-if="item.isIntegrated" class="icon-integrated"></b-icon>
                                        </td>
                                        <td>
                                            {{ item.sku }}		
                                        </td>
                                        <td>{{ item.brand }} {{ item.model }}</td>
                                        <td>{{ item.size }}</td>
                                        <td class="text-center">{{ item.year }}</td>
                                        <td class="text-end">{{ item.stock }}</td>
                                        <td class="text-end">{{ item.price }}</td>
                                        <td class="text-end">{{ item.priceDelivery }}</td>
                                        <td class="text-center">
                                            <strong>{{ item.statusText }}</strong>
                                            <br />
                                            <div class="status-display"><span class="display" :class="{ 'active': item.isShowOnWeb }"></span> {{ item.isShowOnWeb ? 'แสดงบนเว็บ' : 'ไม่แสดงบนเว็บ' }}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <paging
                            @page="changePage($event)"
                            :activePage="activePage"
                            :totalPage="totalPage"
                            class="mt-4">
                        </paging>
                    </div>
                </div>
            </div>

            <modal-save-product-loader
                :isDisplay="isLoadSaveProduct"
                :savedProduct="savedProduct"
                :totalProduct="totalProduct">
            </modal-save-product-loader>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import Helper from '@/services/helper';
import Constants from '@/variables/constants';
import MixinDragScroll from '@/mixins/dragScroll';
import SellerService from '@/services/sellerService';
import MasterService from '@/services/masterService';
import ModalSaveProductLoader from '@/components/seller/stocks/ModalSaveProductLoader';

export default {
	mixins: [ MixinDragScroll ],
    components: { ModalSaveProductLoader },
    data() {
		return {
            isActive: false,
            isSelectAll: false,
			resultList: [],
			activePage: 1,
			totalPage: 0,
            totalResult: 0,
            form: null,
			searchFilter: null,
            option: {
                status: [],
                brand: [],
                model: [],
                width: [],
                ratio: [],
                diameter: [],
                year: Helper.initStockYearOption(),
                integrate: []
            },
            constants: Constants,
			currentSortDirection: Constants.SORT_STOCK_DIRECTION.DEFAULT,
			currentSortColumn: Constants.SORT_STOCK_COLUMN.DEFAULT,
            isLoadSaveProduct: false,
            savedProduct: 0,
            totalProduct: 0
		};
	},
	props: {
		isDisplay: Boolean,
        title: String,
        description: String,
        products: Array,
        keyForIntegration: Number,
        typeForIntegration: String
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);

            if (value) {
                this.form = this.initForm();
                this.searchFilter = this.setupSearchFilter();

                this.getStock();
            }
        }
    },
    mounted() {
		this.initOptionStatus();
		this.initTireBrand();
        this.initTireOption();
        this.initIntegrateOption();
	},
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide() {
            this.$emit('close');
        },
        initForm() {
			return {
				status: Constants.SELLER_STOCK_STATUS.ALL,
				brand: '',
				model: '',
                width: '',
                ratio: '',
                diameter: '',
                year: '',
                integrateType: 'all',
                integrateKey: this.keyForIntegration,
                integrateGroup: this.typeForIntegration,
                sku: ''
			};
		},
        initOptionStatus() {
            const option = [];

            Helper.getStatusList().forEach((item) => {
                option.push({
                    id: item.statusCode,
                    name: item.statusText
                });
            });

            this.option.status = option;
        },
        initIntegrateOption() {
            const option = [];

            option.push({
                id: 'all',
                name: 'ทั้งหมด'
            });

            option.push({
                id: 'select',
                name: 'สินค้าที่ร่วมรายการ'
            });

            option.push({
                id: 'unselect',
                name: 'สินค้าที่ไม่ได้ร่วมรายการ'
            });

            this.option.integrate = option;
        },
		setupSearchFilter() {
			return {
                status: this.form.status,
				param: {
                    sku: this.form.sku,
					brand: this.form.brand,
					model: this.form.model,
					width: this.form.width,
					ratio: this.form.ratio,
					diameter: this.form.diameter,
                    promotion: '',
                    year: this.form.year,
                    integrateType: this.form.integrateType,
                    integrateKey: this.form.integrateKey,
                    integrateGroup: this.form.integrateGroup
				},
				page: this.activePage,
				sortDirection: this.currentSortDirection,
				sortColumn: this.currentSortColumn
			}
		},
        async initTireBrand() {
            const result = await MasterService.getProductBrand();

			this.option.brand = Helper.setupOption(result.data);
        },
        async initTireOption() {
            const result = await MasterService.getAllTireSizeOption();
            
            this.option.width = Helper.setupOption(result.data.width);
            this.option.ratio = Helper.setupOption(result.data.ratio);
            this.option.diameter = Helper.setupOption(result.data.diameter);
        },
        async changeTireSizeOption() {
            setTimeout(async () => {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getTireSizeByCondition(this.form);
                loader.hide();

                this.option.width = Helper.setupOption(result.data.width);
                this.option.ratio = Helper.setupOption(result.data.ratio);
                this.option.diameter = Helper.setupOption(result.data.diameter);
            });
        },
        async changeBrand(brand) {
            if (brand) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getProductModel(brand);
                loader.hide();

				this.option.model = Helper.setupOption(result.data);
            } else {
                this.option.model = [];
            }

            this.form.model = '';
        },
		submitSearch() {
			this.changePage(1);
		},
        resetForm() {
			this.form = this.initForm();
			this.submitSearch();
		},
		async getStock() {
            this.searchFilter = this.setupSearchFilter();

			let loader = this.$modalLoader.render();
	
            const result = await SellerService.getStock(this.searchFilter);

			// Clear data to avoid trigger change in table
			this.resultList = [];

			// After result ready
            this.isSelectAll = false;
			this.totalPage = result.data.totalPage;
            this.totalResult = result.data.totalResult;
			let resultList = result.data.resultList;
            resultList = resultList.map(x => ({ ...x, isIntegrated: this.products.indexOf(x.id) > -1, isSelect: false }));
            
            this.resultList = resultList;

            loader.hide();
		},
		changePage(page) {
			this.activePage = page;
			this.getStock();
		},
        toggleSelection(item, value) {
            item.isSelect = value;
		},
		toggleAllSelection(value) {
            this.isSelectAll = value;

            this.resultList.forEach((item) => {
                this.toggleSelection(item, value);
            });
		},
        async toggleSelctedProduct(flag) {
            const selectedList = this.resultList.filter(x => x.isSelect);

            if (selectedList.length > 0) {
                this.savedProduct = 0;
                this.totalProduct = selectedList.length;
                this.isLoadSaveProduct = true;

                for (let item of selectedList) {
                    this.savedProduct++;

                    if (flag !== item.isIntegrated) {
                        switch (this.typeForIntegration) {
                            case 'promotion':
                                await SellerService.toggleProductPromotion(item.id, flag, this.keyForIntegration);
                                break;

                            case 'service':
                                await SellerService.toggleProductService(item.id, flag, this.keyForIntegration);
                                break;

                            case 'warranty':
                                await SellerService.toggleProductWarranty(item.id, flag, this.keyForIntegration);
                                break;
                        }
                    }
                }

                this.isLoadSaveProduct = false;

                // Done toggle selection
                this.$emit('reloadContent', () => this.getStock());
            }
        },
        async toggleAllProduct(flag) {
            this.totalProduct = 0;
            this.isLoadSaveProduct = true;

            const result = await SellerService.getStockAll(this.searchFilter);

			let resultList = result.data.resultList;
            
            this.savedProduct = 0;
            this.totalProduct = resultList.length;
            this.isLoadSaveProduct = true;

            for (let item of resultList) {
                this.savedProduct++;

                if (flag !== item.isIntegrated) {
                    switch (this.typeForIntegration) {
                        case 'promotion':
                            await SellerService.toggleProductPromotion(item.id, flag, this.keyForIntegration);
                            break;

                        case 'service':
                            await SellerService.toggleProductService(item.id, flag, this.keyForIntegration);
                            break;

                        case 'warranty':
                            await SellerService.toggleProductWarranty(item.id, flag, this.keyForIntegration);
                            break;
                    }
                }
            }

            this.isLoadSaveProduct = false;

            // Done toggle selection
            this.$emit('reloadContent', () => this.getStock());
        },
		sortStock(column) {
            if (this.currentSortColumn === column) {
				switch (this.currentSortDirection) {
					case Constants.SORT_STOCK_DIRECTION.ASC:
					this.currentSortDirection = Constants.SORT_STOCK_DIRECTION.DESC;
					break;

					case Constants.SORT_STOCK_DIRECTION.DESC:
					this.currentSortDirection = Constants.SORT_STOCK_DIRECTION.ASC;
					break;
				}
			} else {
				this.currentSortDirection = Constants.SORT_STOCK_DIRECTION.ASC;
			}

			this.currentSortColumn = column;

			// Reset Page and reload
			this.changePage(1);
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-form {
    max-width: 1200px;

    .info {
        font-size: 13px;
        padding-bottom: 10px;
        border-bottom: 1px dashed #ccc;

        span {
            display: flex;

            strong {
                display: inline-block;
                width: 60px;
            }
        }
    }

    .form {
        .input-field {
            input {
                font-size: 13px;
            }
        }

        .btn {
			padding: 3px 5px;
			width: 120px;

			.icon {
				font-size: 12px;
				margin-right: 5px;
			}

            &.btn-reset {
				background: #ccc;
			}
		}
    }

    .result-text {
        margin-bottom: 10px;
        font-size: 13px;
        display: block;
        text-align: right;

        strong {
            color: #cc0000;
        }
    }

    .action {
        border-top: 1px dashed #ccc;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: $screenExtraSmall) {
            display: block;
        }

        .btn {
            padding: 3px 8px;
            font-size: 13px;
            background: none;

            @media only screen and (max-width: $screenSmall) {
                margin-left: 0;
                margin-top: 5px;
                display: block;
                width: 100%;
            }

            & + .btn {
                margin-left: 2px;

                @media only screen and (max-width: $screenSmall) {
                    margin-left: 0;
                }
            }

            &.btn-add-all {
                border: 1px solid #2ab19e;
                color: #2ab19e;
            }

            &.btn-remove-all {
                border: 1px solid #bf3f4c;
                color: #bf3f4c;
            }

            &.btn-add-selected {
                border: 1px solid #4aa1bb;
                color: #4aa1bb;
            }

            &.btn-remove-selected {
                border: 1px solid #e56e35;
                color: #e56e35;
            }
        }
    }

    table {
        width: 100%;
        font-size: 12px;
        border: 1px solid #ddd;

        th, td {
            padding: 10px;
        }

        thead {
            th {
                background: #eee;
                color: #000;
                font-weight: normal;
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
                min-width: 63px;
                font-size: 12px;
                line-height: 15px;

                &.action-colummn {
                    min-width: 40px;
                }

                &.sortable {
                    cursor: pointer;
                    min-width: 70px;

                    &:hover {
                        text-decoration: underline;
                        opacity: 0.7;
                    }

                    .icon {
                        margin-left: 5px;
                        color: #666;
                    }
                }
            }
        }

        tbody {
            td {
                color: #555;
                border-bottom: 1px solid #ddd;
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
                vertical-align: middle;

                .status-display {
                    @extend .sellerProductStatusDisplay;
                }
            }
        }

        .icon-integrated {
            color: #2ab19e;
            font-size: 24px;
        }

        .btn {
            padding: 0;
            width: 22px;
            height: 22px;
            font-size: 14px;
            line-height: 15px;
            @include borderRadius(0);
        }
    }
}

::v-deep .form {
	.form-option {
        .dropdown {
            .dropdown-wrapper {
                span {
                    font-size: 14px;
                }
            }
        }

		.input-option {
			font-size: 13px;

			&[readonly] {
				background: #fff;
			}
		}
	}
}
</style>