<template>
    <div class="filter input-field">
        <dropdown
            v-model="selectedFilter"
            @change="changeFilter($event)"
            placeholder="แสดงทั้งหมด"
            :classes="{
                input: [
                    'square',
                    'input-option'
                ]
            }"
            :options="optionFilter">
        </dropdown>
    </div>
</template>

<script>
export default {
	data() {
		return {
			selectedFilter: 'all'
		}
	},
    props: {
        optionFilter: Array
    },
	methods: {
        changeFilter(value) {
            this.$emit('changeFilter', value);
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

::v-deep .form-option {
    .input-option {
		font-size: 14px;
        border: 1px solid #999;
        background: #fff;
    }
}
</style>